import { useState, useEffect, useRef, useCallback } from "react";
import { useGlobal } from "reactn";
import classnames from "classnames";
import { TooltipContainer } from "@outplayed/tooltips";
import { getBlockImg } from "@ugg/shared/utils/region-helpers";
import { ReactComponent as TriangleArrowDown } from "@ugg/shared/assets/svg/triangle-arrow-down.svg";

interface RegionProps {
  region: string;
  size?: string;
}

const Region = (props: RegionProps) => {
  const { region, size } = props;

  const regionImage = getBlockImg(region);

  return (
    <div className={classnames("region-selector-item w-[27px] h-[17px]", { "w-[32px] h-[32px]": size === "large" })}>
      {region && regionImage && <img className="max-w-none w-full h-full" src={regionImage} alt="Region Image" />}
    </div>
  );
};

interface RegionSelectorProps {
  currentRegion?: string;
  onApply?: (region: string) => void; // if missing, will set global region
  isMasthead?: boolean;
  disableTooltip?: boolean;
  theme?: "dark" | "light";
}

export default function RegionSelector(props: RegionSelectorProps) {
  const { onApply, currentRegion, isMasthead, disableTooltip, theme = "dark" } = props;

  const selectorRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<{ forceClose: Function }>({ forceClose: () => {} });
  const [clientRegion, setClientRegion] = useGlobal("clientRegion");
  const [modalOpen, setModalOpen] = useState(false);

  const availableRegions = [
    { value: "na1", label: "North America", shorthandLabel: "NA" },
    { value: "euw1", label: "Europe West", shorthandLabel: "EUW" },
    { value: "eun1", label: "Europe Nordic & East", shorthandLabel: "EUN" },
    { value: "kr", label: "Korea", shorthandLabel: "KR" },
    { value: "br1", label: "Brazil", shorthandLabel: "BR" },
    { value: "jp1", label: "Japan", shorthandLabel: "JP" },
    { value: "ru", label: "Russia", shorthandLabel: "RU" },
    { value: "oc1", label: "Oceania", shorthandLabel: "OCE" },
    { value: "tr1", label: "Turkey", shorthandLabel: "TR" },
    { value: "la1", label: "Latin America North", shorthandLabel: "LAN" },
    { value: "la2", label: "Latin America South", shorthandLabel: "LAS" },
    { value: "sg2", label: "Southeast Asia", shorthandLabel: "SEA" },
    { value: "tw2", label: "Taiwan", shorthandLabel: "TW" },
    { value: "vn2", label: "Vietnam", shorthandLabel: "VN" },
    { value: "me1", label: "Middle East", shorthandLabel: "ME" },
  ];

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (selectorRef?.current && e.target !== null && !selectorRef.current.contains(e.target as HTMLElement)) {
        setModalOpen(false);
      }
    };
    if (window) {
      document.addEventListener("click", handleOutsideClick, true);

      return () => document.removeEventListener("click", handleOutsideClick, true);
    }
  }, []);

  useEffect(() => {
    if (!modalOpen) {
      tooltipRef.current.forceClose();
    }
  }, [modalOpen]);

  const setRegion = (region: string) => {
    if (onApply && region && region !== currentRegion) {
      onApply(region);
    }
    // Else, set universal region
    else if (region && region !== clientRegion) {
      localStorage.setItem("summonerRegion", region);
      setClientRegion(region);
    }
    setModalOpen(false);
  };

  return (
    <div className="region-selector-container" ref={selectorRef}>
      <div className={`contents ${theme}-theme`}>
        <TooltipContainer ref={tooltipRef} tooltipInfo="Summoner Region" disableTooltip={disableTooltip}>
          <div className="flex-center region-selector-button" onClick={() => setModalOpen(!modalOpen)}>
            <Region region={currentRegion || clientRegion} size={!isMasthead ? "large" : ""} />
            <div className="arrow-icon">
              <TriangleArrowDown />
            </div>
          </div>
        </TooltipContainer>
        {modalOpen && (
          <div className="region-selector-modal region-selector-list">
            {availableRegions.map((region, index) => (
              <div key={region.value} className={classnames("region-entry")} onClick={() => setRegion(region.value)}>
                <Region region={region.value} />
                <div className={classnames("ml-[12px] region-text")}>{region.label}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
